import Button from "@components/Button";
import { CheckBox, Select } from "@components/core/Form";
import { Modal } from "@components/core/Modal";
import DateFilter from "@components/filters/DateFilter";
import BpmFilter from "@components/filters/common/BpmFilter";
import { DATE_FILTER_FORMAT } from "@lib/constants";
import { ACTIVE_FILTER, BPM_LIMITS } from "@lib/constants/filters";
import { TRACK_SORT_OPTIONS } from "@lib/constants/search";
import { useMediaQuery } from "@lib/hooks/useMediaQuery";
import { DateFilterProps } from "@models/facets";
import { device } from "@styles/theme";
import { format, parse } from "@lib/utils/dateFnsWrapper";
import { useTranslation } from "next-i18next";
import { useCallback, useEffect, useState } from "react";
import "react-day-picker/dist/style.css";
import {
	Field,
	Filter,
	FilterCheckBoxLabelCount,
	FilterCheckBoxLabelText,
	FilterForm,
	FilterFormBody,
	FilterFormFooter,
	Filters,
	MobileWrapper,
	Reset,
	Sorter,
	Wrapper,
} from "./FacetFilter.style";

const SORT_MAP: Record<string, string> = {
	title: "name",
	label: "label",
	genre: "genre",
	release: "release_date",
};

let API4_MAPPINGS: Record<string, string> = {
	genre: "genre_id",
	sub_genre: "sub_genre_id",
	label: "label_id",
	artists: "artist_id",
	key: "key_id",
	type: "type_id",
	bpm: "bpm",
	publish_date: "publish_date",
};

interface Field {
	id: number;
	name: string;
	count: number;
}

interface Facets {
	genre?: Field[];
	sub_genre?: Field[];
	key?: Field[];
	artists?: Field[];
	label?: Field[];
	type?: Field[];
}

interface Props {
	showReleaseFilter?: boolean;
	showBPMFilter?: boolean;
	facets?: Facets;
	hide?: string[];
	defaults?: Record<string, any>;
	onChange: (filters: Record<string, string>) => void;
	onReset: () => void;
	overrideFacets?: Record<string, string>;
}

const FacetFilter: React.FC<Props> = ({
	showReleaseFilter,
	showBPMFilter,
	facets,
	hide,
	defaults = {},
	onChange,
	onReset,
	overrideFacets,
}) => {
	if (overrideFacets) {
		API4_MAPPINGS = overrideFacets;
	}
	const isHidden = useCallback(
		(name: string) => (hide && hide.includes(name)) || false,
		[hide],
	);

	const { t } = useTranslation("translation");
	const isDesktop = useMediaQuery({ query: device.lg });
	const [state, setState] = useState<Record<string, string[]>>({});
	const [activeFilterKey, setActiveFilterKey] = useState("");
	const [bpmFilter, setBpmFilter] = useState({ min: 0, max: 0 });
	const [dateFilter, setDateFilter] = useState<DateFilterProps>({
		min: undefined,
		max: undefined,
		visible: "",
		inputLabel: "",
		inputName: "",
	});

	useEffect(() => {
		const initialState: Record<string, string[]> = {};
		if (defaults && facets) {
			Object.keys(facets)
				.filter((key) => {
					const data = (facets as any)[key] as Field[];
					return !isHidden(key) && data && data.length > 0;
				})
				.map((key) => {
					const realKey = API4_MAPPINGS[key];
					const val = defaults[realKey];
					if (val) {
						initialState[key] = val.split(",");
					}
				});
		}
		setState(initialState);

		if (showReleaseFilter && defaults) {
			const vals = defaults["publish_date"];
			if (vals) {
				const parts = vals.split(":");
				if (parts.length === 2) {
					const min = parse(parts[0] as string, DATE_FILTER_FORMAT, new Date());
					const max = parse(parts[1] as string, DATE_FILTER_FORMAT, new Date());
					if (!isNaN(min.valueOf()) && !isNaN(max.valueOf())) {
						setDateFilter({
							min: min,
							max: max,
							visible: "",
							inputLabel: "",
							inputName: "",
						});
					}
				}
			}
		}

		if (showBPMFilter && defaults) {
			const vals = defaults["bpm"];
			if (vals) {
				const parts = vals.split(":");
				if (parts.length === 2) {
					const min = parseInt(parts[0]);
					const max = parseInt(parts[1]);
					if (!isNaN(min) && !isNaN(max)) {
						setBpmFilter({ min: min, max: max });
					}
				}
			}
		}
	}, [JSON.stringify(defaults), facets, isHidden, showBPMFilter, showReleaseFilter]);

	const updateGroupItem = (name: string, value: string) => {
		const groupState = state[name] || [];
		if (groupState.includes(value)) {
			// remove item
			groupState.splice(groupState.indexOf(value), 1);
		} else {
			groupState.push(value);
		}

		state[name] = groupState;
		setState({ ...state });
	};

	const apply = () => {
		const data = {} as Record<string, string>;
		Object.keys(state).forEach((key) => {
			if (API4_MAPPINGS[key]) {
				data[API4_MAPPINGS[key]] = state[key].join(",");
			}
		});

		onChange(data);
		setActiveFilterKey("");
	};

	const applySort = (sort: string) => {
		const parts = sort.split("-");
		const data = {} as Record<string, string>;
		data["order_by"] = `${parts[1] === "asc" ? "" : "-"}${SORT_MAP[parts[0]]}`;

		Object.keys(state).forEach((key) => {
			if (API4_MAPPINGS[key]) {
				data[API4_MAPPINGS[key]] = state[key].join(",");
			}
		});

		onChange(data);
	};

	const applyReleaseDate = () => {
		const data = {} as Record<string, string>;
		const min = dateFilter.min ?
				format(dateFilter.min, DATE_FILTER_FORMAT) :
				format(new Date(), DATE_FILTER_FORMAT);
		const max = dateFilter.max ?
				format(dateFilter.max, DATE_FILTER_FORMAT) :
				format(new Date(), DATE_FILTER_FORMAT);

		data["publish_date"] = `${min}:${max}`;
		onChange(data);
		setActiveFilterKey("");
	};

	const applyBpm = () => {
		const data = {} as Record<string, string>;
		const bpmMin =
			bpmFilter.min < BPM_LIMITS.min || isNaN(bpmFilter.min) ?
				BPM_LIMITS.min :
				bpmFilter.min;
		const bpmMax =
			bpmFilter.max < BPM_LIMITS.min || isNaN(bpmFilter.max) ?
				BPM_LIMITS.max :
				bpmFilter.max;
		data["bpm"] = `${bpmMin}:${bpmMax}`;
		onChange(data);
		setActiveFilterKey("");
	};

	const resetGroup = (name: string) => {
		state[name] = [];
		setState({ ...state });
		apply();
	};

	const resetAll = () => {
		setState({});
		setBpmFilter({ min: 0, max: 0 });
		setDateFilter({
			min: undefined,
			max: undefined,
			visible: "",
			inputLabel: "",
			inputName: "",
		});
		onReset();
		setActiveFilterKey("");
	};

	const renderFilters = (group: string, fields: Field[]) => {
		const groupState = state[group] || [];

		return (
			<FilterForm key={`group-${group}`} className={`group-${group}`}>
				<FilterFormBody>
					<ul>
						{fields
							.filter((item) => item.id !== null)
							.map((item) => (
								<Field key={`group-${group}-${item.id}`}>
									<CheckBox
										label={(
											<>
												<FilterCheckBoxLabelText>{item.name}</FilterCheckBoxLabelText>
												<FilterCheckBoxLabelCount>({item.count})</FilterCheckBoxLabelCount>
											</>
										)}
										id={item?.id?.toString()}
										name={item.name}
										value={item.id}
										checked={groupState.includes(item?.id?.toString())}
										onChange={() => {
											updateGroupItem(group, item?.id?.toString());
										}}
									/>
								</Field>
							))}
					</ul>
				</FilterFormBody>
				<FilterFormFooter>
					<Button type="link" onClick={() => resetGroup(group)}>
						{t("Reset")}
					</Button>
					<Button
						type="primary"
						onClick={() => apply()}
						disabled={groupState.length === 0}
					>
						{t("Apply")}
					</Button>
				</FilterFormFooter>
			</FilterForm>
		);
	};

	const renderReleaseFilter = () => (
		<DateFilter
			dateFilter={dateFilter}
			setDateFilter={setDateFilter}
			applyDateFilter={applyReleaseDate}
			resetGroup={() => resetGroup("publish_date")}
		/>
	);

	const renderBPMFilter = () => (
		<FilterForm className="group-bpm">
			<FilterFormBody>
				<BpmFilter.Inputs bpmValues={bpmFilter} onSetBpm={setBpmFilter} />
			</FilterFormBody>
			<FilterFormFooter>
				<BpmFilter.Buttons
					bpmValues={bpmFilter}
					onReset={(data) => {
						resetGroup("bpm");
						setBpmFilter(data);
					}}
					onApply={applyBpm}
				/>
			</FilterFormFooter>
		</FilterForm>
	);

	const renderActiveForm = (key: string) => {
		if (key === "release_date") {
			return renderReleaseFilter();
		} else if (key === "bpm") {
			return renderBPMFilter();
		} else if (facets && key !== "") {
			const data = (facets as any)[key] as Field[];
			return renderFilters(key, data);
		}
	};

	const renderData = (data: Facets) =>
		Object.keys(data)
			.filter((key) => {
				const data = (facets as any)[key] as Field[];
				return !isHidden(key) && data && data.length > 0;
			})
			.map((key) => {
				const groupName = key.charAt(0).toUpperCase() + key.slice(1);
				const data = (facets as any)[key] as Field[];

				return (
					<Filter
						className={
							defaults[API4_MAPPINGS[key]] ? ACTIVE_FILTER : undefined
						}
						key={`facet-${key}`}
						id={`facet-${key}`}
						onClick={() => {
							if (!isDesktop) {
								setActiveFilterKey(key);
							}
						}}
					>
						<span>{groupName.replace("_", "-")}</span>
						<i className="arrow" />
						{isDesktop && renderFilters(key, data)}
					</Filter>
				);
			});

	return (
		<Wrapper>
			<Sorter>
				<span>{t("SortBy")}</span>
				<Select
					id="sort"
					name="sort"
					options={TRACK_SORT_OPTIONS}
					onChange={(e) => {
						applySort(e.target.value);
					}}
				/>
			</Sorter>
			<Filters>
				{showReleaseFilter && (
					<Filter
						className={
							defaults[API4_MAPPINGS.publish_date] ? ACTIVE_FILTER : undefined
						}
						onClick={() => {
							if (!isDesktop) {
								setActiveFilterKey("release_date");
							}
						}}
					>
						<span>{t("ReleaseDate")}</span>
						<i className="arrow" />
						{isDesktop && renderReleaseFilter()}
					</Filter>
				)}
				{showBPMFilter && (
					<Filter
						className={
							defaults[API4_MAPPINGS.bpm] ? ACTIVE_FILTER : undefined
						}
						onClick={() => {
							if (!isDesktop) {
								setActiveFilterKey("bpm");
							}
						}}
					>
						<span>BPM</span>
						<i className="arrow" />
						{isDesktop && renderBPMFilter()}
					</Filter>
				)}
				{facets && renderData(facets)}

				<Reset onClick={() => resetAll()}>{t("ResetAll")}</Reset>
				<Modal
					show={activeFilterKey !== ""}
					onClose={() => {
						setActiveFilterKey("");
					}}
				>
					<MobileWrapper>{renderActiveForm(activeFilterKey)}</MobileWrapper>
				</Modal>
			</Filters>
		</Wrapper>
	);
};

export default FacetFilter;
